<template>
  <div id="termsOfUseContainer">
    <h3>Terms of Use</h3>
    <p>THIS IS A LEGAL AGREEMENT THAT ALLOWS YOU TO ACCESS AND USE THE AQUASTARINC.COM WEBSITE. BY ACCESSING THE WEBSITE YOU ACCEPT AND AGREE TO BE BOUND BY THESE TERMS. PLEASE READ THIS PAGE CAREFULLY. IF YOU DO NOT AGREE TO THE TERMS STATED HERE, DO NOT USE THE WEBSITE AND SERVICE. AQUASTARINC.COM MAY REFUSE ACCESS TO THE WEBSITE FOR NONCOMPLIANCE WITH ANY OF THE TERMS OF THIS AGREEMENT</p>
    <ol>
      <li>
        Acceptance of Terms of Use
        <ul>
          <li>aquastarinc.com (the "Website") is a sanitary fittings showcase website operated by Aquastar Pvt Ltd for Trade and Business (referred to as “aquastarinc.com” or “we”). Contents (as defined in Clause 3 below) is uploaded by aquastarinc.com and other users of the Website.</li>
          <li>These are the terms on which aquastarinc.com allows you to access and use the Website, whether as a guest or a registered user. Please read these Terms of Use carefully before you start to use the Website.</li>
          <li>By accessing and using the Website, you are accepting these Terms of Use and agreeing to abide by them.</li>
          <li>If you do not agree to these Terms of Use, then you are not permitted to use the Website.</li>
        </ul>
      </li>
      <li>
        aquastarinc.com’s Proprietary Rights
        <ul>
          <li>You agree that aquastarinc.com is the owner or the licensee of all intellectual property rights in the Website, and the Website itself is a collective work and/or compilation solely owned by aquastarinc.com. The Website is protected by copyright laws and treaties around the world.</li>
          <li>You may not copy, distribute, reproduce, sell, lease, assign, rent, sublicense, encumber any aspect of the Website or any Content (as defined in Clause 3 below).</li>
          <li>You also may not modify or create derivative works of any aspect of the Website or any Content (as defined in Clause 3 below), other than with respect to your own Content (as defined in Clause 3 below).</li>
          <li>You may not disassemble or decompile, reverse engineer or otherwise attempt to discover any source code contained in the Website or any software or database connected to the Website.</li>
        </ul>
      </li>

      <li>
        Content
        <ul>
          <li>"Content" refers to any listing, forums, event listings, reviews, postings, messages, text, files, images, photos or any other material posted on the Website.</li>
          <li>aquastarinc.com aims to update the Website regularly and may change the Content at any time. Any of the Content may be out of date at any given time, and we are under no obligation to update such material.</li>
        </ul>
      </li>
      <li>
        Links to Third Party Sites
        <ul>
          <li>Where the Website contains links to other sites and resources provided by third parties, these links are provided for your information only.</li>
          <li>We have no control over the contents of those sites or resources, and accept no responsibility for them or for any loss or damage that may arise from your use of them.</li>
        </ul>
      </li>
      <li>
        Notification of Claims of Infringement
        <ul>
          <li>It is forbidden to post any Content that violates any international, federal, national, local copyright, patent, trademark or other intellectual property laws applicable in The State of Qatar.</li>
          <li>aquastarinc.com is not responsible for any Content submitted by a user that infringes another’s proprietary rights.</li>
          <li>If you are aware of any Content that you believe infringes on yours or a third party’s proprietary rights, please contact us.</li>
          <li>
            Please include in any such notice:
            <ul>
              <li>a link to the alleged infringing material;</li>
              <li>a description of the alleged infringing material;</li>
              <li>a statement contesting that the copyrighted material was not posted by the holder or permitted licensee of said copyright;</li>
              <li>your contact details (including email address); and</li>
              <li>your signature (physical or electronic).</li>
            </ul>
          </li>
          <li>aquastarinc.com may, but is under no obligation to screen or monitor any Content. If notified of Content which allegedly does not conform to these Terms of Use or infringes a third party’s proprietary rights, aquastarinc.com may investigate the allegation and determine in its sole discretion whether to remove or request the removal of the Content. aquastarinc.com has no liability or responsibility to users for performance or non-performance of such activities.</li>
        </ul>
      </li>
      <li>
        Privacy Policy
        <p>aquastarinc.com respects your privacy and has developed a detailed Privacy Policy that is incorporated into this Agreement. Please take the time to read our Privacy Policy found here. By agreeing to these Terms, you are also accepting the terms of our Privacy Policy.</p>
      </li>
      <li>
        Limitations on Your Usage of the Website
        <p>aquastarinc.com may establish any limitations, in our sole discretion, on your usage of the Website, including, but not limited to, limitations on the length of time that Content may remain on the Website, the file size of an individual item of Content, number of items of Content that can be posted.</p>
      </li>
      <li>
        Conduct & Acceptable Use; No Spam
        <ul>
          <li>
            You agree to not post, email, or make available through the Website any Content that is objectionable to aquastarinc.com or in contradiction to applicable laws or regulations. In this regard, you agree not to post, email or make available through the Website any Content that:
            <ul>
              <li>is unlawful, harmful, threatening, abusive, defamatory, libelous, invasive or any information harmful to minors;</li>
              <li>is pornographic or any kind of nudity, sexually explicit or perverse content;</li>
              <li>is racist or discriminatory content towards any race, religion, creed, nationality, gender or any other grouping of individuals;</li>
              <li>impersonates any another person;</li>
              <li>includes personally identifiable information about another person without their permission;</li>
              <li>is false or deceptive;</li>
              <li>solicits the sale of goods or services, except when (1) posted in the appropriately designated area of the Website for such sales, or (2) emailed to another user of the Website who expressly wish to be contacted by you;</li>
              <li>includes links to other websites, except when (1) expressly approved by aquastarinc.com in writing, and (2) posted in a posting uploaded by you in the appropriately designated area;</li>
              <li>advertises the sale of anything illegal under any applicable law or regulation in the jurisdiction you are selling the product from or to;</li>
              <li>violates any applicable international, federal, national, local copyright, patent, trademark or other intellectual property laws;</li>
              <li>contains any software viruses, trojans, worms, logic bombs or other material which is malicious or technologically harmful;</li>
              <li>disrupts the normal flow with an excessive number of messages; or</li>
              <li>uses misleading contact details to disguise the true origin.</li>
            </ul>
          </li>
          <li>
            You further agree not to:
            <ul>
              <li>contact people who do not wish to be contacted;</li>
              <li>stalk or harass other users of the Website;</li>
              <li>collect personal information about users for commercial or unlawful purposes;</li>
              <li>post Content that is irrelevant or unrelated to the category or discussion thread where such Content is posted;</li>
              <li>post the same good or service in more than two categories;</li>
              <li>to post, email, make available through the Website or contact aquastarinc.com or any other users of the Website with any spam, junk mail, chain letters, pyramid or Ponzi schemes, or unsolicited email advertisements; or</li>
              <li>
                attempt to gain unauthorized access to the Website, the server on which the Website is stored or any server, computer or database connected to the Website,
                for the avoidance of doubt, the above list of prohibited content and actions is not exhaustive and aquastarinc.com reserves the right to (i) remove any content that it considers (in its absolute and sole discretion) to be objectionable or in contradiction to applicable laws or regulations without notice; and (ii) take necessary steps to prevent persons who post content and/or conduct themselves in contravention of this Clause 9 from accessing the Website.
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        Disclaimer of Warranties
        <ul>
          <li>You use the Website at your own risk.</li>
          <li>The Website is provided without warranty and is provided on an “as is, as available” basis.</li>
          <li>
            AQUASTARINC.COM HEREBY DISCLAIMS ANY REPRESENTATIONS OR WARRANTIES OF ANY KIND, WHETHER ORAL OR WRITTEN, WHETHER EXPRESS, IMPLIED, OR ARISING BY STATUTE, CUSTOM, COURSE OF DEALING OR TRADE USAGE, WITH RESPECT TO THE WEBSITE OR ANY SERVICES PROVIDED HEREUNDER. NOTWITHSTANDING THE GENERALITY OF THE FOREGOING, AQUASTARINC.COM SPECIFICALLY DISCLAIMS THE FOLLOWING WITH RESPECT TO THE WEBSITE:
            <ul>
              <li>IMPLIED WARRANTIES OR CONDITIONS OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT OF ANY SERVICE OR GOOD ACQUIRED BY YOU FROM A THIRD PARTY;</li>
              <li>OF THE ACCURACY, TIMELINESS, SECURITY AND PERFORMANCE OF THE WEBSITE; OR</li>
              <li>FREEDOM FROM ANY ERRORS OR VIRUSES, TROJANS, WORMS, LOGIC BOMBS OR OTHER MATERIAL WHICH IS MALICIOUS OR TECHNOLOGICALLY HARMFUL.</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        Limitation of Liabilities
        <ul>
          <li>To the maximum extent permitted by applicable law, aquastarinc.com hereby expressly excludes any liability for any damages whatsoever (including, without limitation, direct, indirect, special, punitive, incidental or consequential loss or damage incurred by any user in connection with the Website or in connection with the use, inability to use, or results of the use of the Website, any websites linked to it and any materials posted on it, including, without limitation any liability for: loss of income or revenue; loss of business; loss of profits or contracts; loss of anticipated savings; loss of data; loss of goodwill; wasted management or office time; and for any other loss or damage of any kind, however arising and whether caused by tort (including negligence), breach of contract or otherwise, even if foreseeable.</li>
          <li>
            In particular, aquastarinc.com is not liable for any damages or losses resulting to you or a third party from:
            <ul>
              <li>the use or the inability to use the Website;</li>
              <li>services or goods acquired through the Website;</li>
              <li>any Content displayed on the Website; or</li>
              <li>a distributed denial-of-service attack, viruses or other technologically harmful material that may infect your computer equipment, computer programs, data or other proprietary material due to your use of the Website or to your downloading of any material posted on it, or on any website linked to it.</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        Indemnity
        <p>You agree to indemnify, defend and hold harmless aquastarinc.com, its shareholders, officers, directors, employees, and agents from any claims, actions, demands, damages, losses or attorney fees alleging or resulting from your use or misuse of the Website or any Content, your breach of these Terms of Use, your violation of any third party’s proprietary rights, or any disputes between you and one or more users connected to or arising from the Website in any way.</p>
      </li>
      <li>
        Termination of Access to the Website
        <ul>
          <li>aquastarinc.com reserves the right to block you or terminate you from accessing the Website for any breach of these Terms of Use at any time without any notice.</li>
          <li>aquastarinc.com reserves the right to delete any of your Content from the Website at any time.</li>
          <li>aquastarinc.com is not liable to you or a third party for any termination of your access to the Website or deletion of your posted Content.</li>
          <li>You agree not to try to use the Website after said termination.</li>
        </ul>
      </li>

      <li>
        Violations of Terms of Use
        <p>Please notify us if you become aware of any violations by third parties of these Terms of Use.</p>
      </li>
      <li>
        Enforcement of Terms of Use
        <p>aquastarinc.com reserves the right to take steps aquastarinc.com believes are reasonably necessary or appropriate to enforce and/or verify compliance with any of these Terms of Use (including, but not limited to aquastarinc.com’s right to cooperate with any legal process relating to your use of the Website, and/or a third party claim that your use of the Website is unlawful and/or infringes such third party’s rights). You agree that aquastarinc.com has the right without liability to you to disclose any registration data and/or account information to law enforcement authorities, government officials and/or third parties, as aquastarinc.com believes is reasonably necessary or appropriate to enforce and/or verify compliance with any of these Terms of Use (including, but not limited to aquastarinc.com’s right to cooperate with any legal process relating to your use of the Website, and/or a third party claim that your use of the Website is unlawful and/or infringes such third party’s rights).</p>
      </li>
      <li>
        General
        <ul>
          <li>You agree that the fact that these Terms of Use are in electronic format does not affect in any way their validity or enforceability.</li>
          <li>These Terms of Use (together with any Prepaid Listing Account Booking Form) constitute the entire agreement between you and aquastarinc.com regarding its subject matter and supersede any contemporaneous or prior oral or written agreements, representations, understandings or discussions between you and aquastarinc.com, whether implied or express.</li>
          <li>aquastarinc.com ’s delay or failure to exercise a right or act on a breach of these Terms of Use does not waive our right to do so in the future.</li>
          <li>The provisions of these Terms of Use shall be deemed severable, and if any provision is determined to be illegal or invalid under applicable law, such provision may be changed to the extent reasonably necessary to make the provision, as so changed, legal, valid and binding.</li>
          <li>You may not assign any part of these Terms of Use without aquastarinc.com ’s express written permission. aquastarinc.com may assign these Terms of Use immediately upon posting a notice of such assignment on the Website.</li>
        </ul>
      </li>
      <li>
        Governing Law & Dispute Resolution
        <ul>
          <li>The Governing law of this Agreement shall be the substantive law of the State of Qatar.</li>
          <li>In the event of any disputes between you and aquastarinc.com, aquastarinc.com is open to first attempting to resolve the dispute in an informal and amicable manner.</li>
          <li>However, if either party believes that the dispute cannot be resolved in such a manner, then the parties agree that either party may refer any dispute arising out of, or in connection with, these Terms of Use, including any question regarding its existence, validity or termination, to be finally settled by binding arbitration.</li>
          <li>Any dispute arising out of or in connection with this Agreement, including any question regarding its existence, validity or termination, shall be referred to and finally resolved by arbitration under the Qatar International Centre for Conciliation and Arbitration Rules 2012 (Rules) which Rules are deemed to be incorporated by reference into this Clause.</li>
          <li>The seat or legal place of arbitration shall be at the Qatar International Centre for Arbitration in Doha, State of Qatar. The proceedings shall be conducted in Doha. Judgment upon the award rendered by the arbitrator(s) may be entered by any court having jurisdiction thereof or having jurisdiction over the relevant party or its assets.</li>
          <li>The language to be used in the arbitral proceedings shall be Arabic, and the award shall be issued in Arabic.</li>
        </ul>
      </li>
      <li>
        Modifications to Terms of Use
        <p>aquastarinc.com reserves the right at any time and from time to time to update, revise, supplement and otherwise modify these Terms of Use and to impose new and/or additional rules, policies, terms and/or conditions on your use of the Website (collectively referred to as “Additional Terms”). Such Additional Terms will be effective immediately and incorporated into these Terms of Use. Your continued use of the Website constitutes your acceptance of all such Additional Terms. Any Additional Terms are hereby incorporated into these Terms of Use by this reference.</p>
      </li>
    </ol>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
#termsOfUseContainer {
  margin-top: $headerHeight;
  padding: 2rem;
  color:$darkBlue;
  p {
    padding: 1rem;
    margin-top: 0;
  }
  ol {
    padding-left: 10px;
    ul {
      padding-left: 20px;
      ul {
        padding-left: 30px;
      }
    }
  }
  li {
    list-style-position: inside;
  }
}
</style>
